$(function() {
  let clientId = $(".work-order-client").val();

  if (clientId) {
    $.ajax({
      type: "GET",
      url: "/work_orders/" + clientId + "/client_sites",
      dataType: "script",
      data: { id: clientId }
    });  
  }
  

  $(document).on("change", ".work-order-client", function() {
    let clientId = $(this).val();

    $.ajax({
      type: "GET",
      url: "/work_orders/" + clientId + "/client_sites",
      dataType: "script",
      data: { id: clientId }
    });
  });

  let initialOrderType = $(".order-type").val();
  if (initialOrderType == "Periodical") {
    $(".order-type-other").addClass("hide");
    $(".order-type-periodical").removeClass("hide");
  }
  else if (initialOrderType == "Other") {
    $(".order-type-periodical").addClass("hide");
    $(".order-type-other").removeClass("hide");
  }
  else {
    $(".order-type-periodical").addClass("hide");
    $(".order-type-other").addClass("hide");
  }

  $(document).on("change", ".order-type", function() {
    let orderType = $(this).val();
    $(".order-type-other").val("");
    $(".order-type-periodical-dropdown").val("").trigger('change');
    if (orderType == "Periodical") {
      $(".order-type-other").addClass("hide");
      $(".order-type-periodical").removeClass("hide");
    }
    else if (orderType == "Other") {
      $(".order-type-periodical").addClass("hide");
      $(".order-type-other").removeClass("hide");
    }
    else {
      $(".order-type-periodical").addClass("hide");
      $(".order-type-other").addClass("hide");
    }
  });

  $(document).on("turbolinks:load", function() {
    let initialOrderType = $(".order-type").val();
    if (initialOrderType == "Periodical") {
      $(".order-type-other").addClass("hide");
      $(".order-type-periodical").removeClass("hide");
    }
    else if (initialOrderType == "Other") {
      $(".order-type-periodical").addClass("hide");
      $(".order-type-other").removeClass("hide");
    }
    else {
      $(".order-type-periodical").addClass("hide");
      $(".order-type-other").addClass("hide");
    }

    $(".work-order-services").select2({closeOnSelect:false});
  });

  $(".work-order-services").select2({closeOnSelect:false});

  $(document).on("change", ".client-cost, .client-gst", function(){
    let clientCostWithGst = $(".client-cost-with-gst")
    let clientCost = $(".client-cost").val();

    if($(".client-gst").prop('checked') == true) {
      clientCostWithGst.val(parseFloat(clientCost).toFixed(2));
    }
    else {
      clientCostWithGst.val((parseFloat(clientCost) + ((clientCost * 10)/100)).toFixed(2));
    }
  });

  $(document).on("change", ".staff-cost, .staff-gst", function(){
    let staffCostWithGst = $(".staff-cost-with-gst")
    let staffCost = $(".staff-cost").val();

    if($(".staff-gst").prop('checked') == true) {
      staffCostWithGst.val(parseFloat(staffCost).toFixed(2));
    }
    else {
      staffCostWithGst.val((parseFloat(staffCost) + ((staffCost * 10)/100)).toFixed(2));
    }
  });
});
