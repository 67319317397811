$(function() {
  $(document).on("click", ".display-picture-button", function(e){
    e.preventDefault();
    $(this).text(($(this).text() == "Change Display Picture") ? "Collapse" : "Change Display Picture");
    $(this).toggleClass("fa-caret-down fa-caret-up");
    $(".display-picture-field").toggleClass("hide");
  });

  $(document).on("click", ".change-password-button", function(e){
    e.preventDefault();
    $(this).text(($(this).text() == "Change Password") ? "Collapse" : "Change Password");
    $(this).toggleClass("fa-caret-down fa-caret-up");
    $(".change-password-field").toggleClass("hide");
  });

  $("#skip").trigger("submit");
});
