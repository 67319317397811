var PG = PG || {};

PG.toggelDismissIcon = function() {
  let targetTable = $("." + $(".add-more-fields").data("target-table"));
  if(targetTable.find("tbody tr").length == 1) {
    targetTable.find(".dismiss-row-icon").hide();
  } else {
    targetTable.find(".dismiss-row-icon").show();
  }
};

PG.toggelPhotosDismissIcon = function() {
  let targetForm = $("." + $(".add-more-upload-fields").data("target-form"));
  if(targetForm.find(".upload-field").length == 1) {
    targetForm.find(".upload-field .upload-field-dismiss-row-icon").last().hide();
  } else {
    targetForm.find(".upload-field-dismiss-row-icon").show();
  }
};

$(function() {
  $(document).on("click", ".add-more-fields", function(e) {
    e.preventDefault();
    let targetTable = $("." + $(this).data("target-table"));
    let targetTr = targetTable.find("tbody tr");
    let new_input_row = targetTr.last().clone();
    let new_id = targetTr.length;
    let to_be_replaced = new_input_row.find(".batch-addition").first().attr("name").match(/\d+/)[0];

    new_input_row.find("input").map(function() {
      let $this = $(this);
      $this.attr("name", $this.attr("name").replace(to_be_replaced, new_id));
      $this.attr("id", $this.attr("id").replace(to_be_replaced, new_id));
      $this.val("");
    });

    targetTable.append(new_input_row);
    PG.toggelDismissIcon();
  });

  $(document).on("click", ".dismiss-row-icon", function() {
    let itemRow = $(this).parent().parent();
    itemRow.remove();
    PG.toggelDismissIcon();
  });


  $(document).on("click", ".add-more-upload-fields", function(e) {
    e.preventDefault();
    let targetForm = $("." + $(this).data("target-form"));
    let targetField = targetForm.find(".upload-field");
    let new_input_row = targetField.last().clone();
    let new_id = targetField.length;
    let to_be_replaced = new_input_row.find(".batch-addition").first().attr("name").match(/\d+/)[0];

    new_input_row.find("input").map(function() {
      let $this = $(this);
      $this.attr("name", $this.attr("name").replace(to_be_replaced, new_id));
      $this.attr("id", $this.attr("id").replace(to_be_replaced, new_id));
      $this.val("");
    });

    targetForm.append(new_input_row);
    PG.toggelPhotosDismissIcon();
  });

  $(document).on("click", ".upload-field-dismiss-row-icon", function() {
    let itemRow = $(this).parent().parent();
    itemRow.remove();
    PG.toggelPhotosDismissIcon();
  });
});
