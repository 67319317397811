var PG = PG || {};

PG.toggelTaxDismissIcon = function() {
  let targetTable = $("." + $(".add-tax-details-row").data("target-table"));
  if(targetTable.find("tbody tr").length == 1) {
    targetTable.find(".tax-dismiss-row-icon").hide();
  } else {
    targetTable.find(".tax-dismiss-row-icon").show();
  }
};

$(function() {
  $(document).on("click", ".remove-photo", function(e) {
    e.preventDefault();
    let targetDiv = $(this).closest("div");
    let cleanerId = targetDiv.data("cleaner-id");
    $(this).hide();

    $.ajax({
      type: "PUT",
      url: "/cleaners/" + cleanerId + "/remove_photo",
      data: { id: cleanerId },
      success: function() {
        location.reload();
      }
    });
  });

  $(document).on("click", ".remove-certificate-button", function(e) {
    e.preventDefault();
    let targetDiv = $(this).closest("div");
    let inductionDetailId = targetDiv.data("induction-detail-id");
    let verificationId = targetDiv.data("verification-id");
    let targetId = inductionDetailId || verificationId;
    let targetUrl;

    $(this).hide();
    $(targetDiv).find(".document-name").hide();
    $(targetDiv).find(".target-document-field").show();

    if(inductionDetailId)
      targetUrl = "/induction_details/" + inductionDetailId + "/remove_certificate";
    else
      targetUrl = "/verifications/" + verificationId + "/remove_certificate";

    $.ajax({
      type: "PUT",
      url: targetUrl,
      data: { id: targetId }
    });
  });

  $(document).on("click", ".add-tax-details-row", function(e) {
    e.preventDefault();
    let cleanerId = $(".cleaner-id").val();

    $.get("/tax_details/add_new_tax_details_row", function () {
      $("tr").last().find('select').select2({
        theme: "bootstrap"
      });
      PG.toggelTaxDismissIcon();
    });
  })

  $(document).on("click", ".tax-dismiss-row-icon", function() {
    let itemRow = $(this).parent().parent();
    itemRow.remove();
    PG.toggelTaxDismissIcon();
  });
});
