$(function() {
  $(document).on("change", "#all_sites", function() {
    if ($(this).prop("checked")) {
      $(".work-report-sites").prop("disabled", true);
      $(".work-report-clients").prop("disabled", true);
    } else {
      $(".work-report-sites").prop("disabled", false);
      $(".work-report-clients").prop("disabled", false);
    }
  });

  $(document).on("change", ".hours-worked, .hourly-pay-rate", function(){
    let payment = $(".total-payment");
    let hoursWorked = $(".hours-worked").val();
    let payRate = $(".hourly-pay-rate").val();

    if(hoursWorked && payRate) {
      payment.val((hoursWorked * payRate).toFixed(2));
    } else {
      payment.val("0");
    }
  });

  $(document).on("change", ".missed-unpaid-status", function() {
    let workType = $(this).children().val();
    let x = $(".currencies-work-report").children()[0]

    if(workType == "Missed-Unpaid"){
      $(".no-of-hours-worked").val(0.0);
      $(".work-reports-pay_rate").val(0);
      $(".no-of-hours-worked").prop("readonly", true);
      $(".work-reports-pay_rate").prop("disabled", true);
      $(x).val("").trigger('change');
      $(".currencies-work-report").children().prop("disabled", true);
    }else{
      $(".no-of-hours-worked").prop("readonly", false);
      $(".work-reports-pay_rate").prop("disabled", false);
      $(".currencies-work-report").children().prop("disabled", false);
    }
  });

  $(".work-report-multiselect-dropdown").select2({ closeOnSelect: false });

  $(document).on("turbolinks:load", function() {
    $(".work-report-multiselect-dropdown").select2({ closeOnSelect: false });
  });
});
