// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("select2")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.jQuery = $;
window.$ = $;

import "select2/dist/css/select2.min.css";
import "select2-bootstrap-theme/dist/select2-bootstrap.min.css";

require("packs/batch_addition")
require("packs/cleaner")
require("packs/common")
require("packs/edit_user")
require("packs/equipments")
require("packs/induction_details")
require("packs/modal")
require("packs/payrun")
require("packs/shift")
require("packs/site")
require("packs/site_work_report")
require("packs/verifications")
require("packs/work_reports")
require("packs/work_orders")
require("packs/public_holidays")
