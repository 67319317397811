$(function() {
  let clientId = $(".current-site-client").val();

  if (clientId) {
    $.ajax({
      type: "GET",
      url:  "/sites/" + clientId + "/client_contract",
      dataType: "script",
      data: { id: clientId }
    });
  }

  $(document).on("change", ".current-site-client", function() {
    let clientId = $(this).val();

    if (clientId) {
      $.ajax({
        type: "GET",
        url:  "/sites/" + clientId + "/client_contract",
        dataType: "script",
        data: { id: clientId }
      });
    }
  });

  $(document).on("change", ".site-cleaner-missed-clean", function() {
    let siteCleanerId = $(".site-cleaner-missed-clean").val();
    let missedCleanSiteId = $(".missed-clean-site").val();

    if (siteCleanerId) {
      $.ajax({
        type: "GET",
        url:  "/missed_cleans/" + siteCleanerId + "/cleaner_shifts",
        dataType: "script",
        data: { id: siteCleanerId, site_id: missedCleanSiteId }
      });
    }
  });



  $(document).on("change", ".current-client-contract", function() {
    let contractId = $(this).val();

    if (contractId) {
      $.ajax({
        type: "GET",
        url:  "/sites/" + contractId + "/contract_status",
        dataType: "script",
        data: { contract_id: contractId }
      });
    } else {
      $(".site-contract-status").val("");
    }
  });

  $(document).on("click", ".modal-open-form-icon", function() {
    $(".showpage-modal").addClass("hide");
  });

  $(document).on("click", ".modal-close-button, .modal-form-cancel-button", function() {
    $(".showpage-modal").find(".modal-header > .close").trigger("click");
  });

  $(document).on("click", ".equipment-test-not-applicable", function() {
    $(".equipment-test-not-applicable-item").attr("disabled", this.checked);
    if($(".equipment-test-not-applicable").prop('checked') == true) {
      $(".equipment-test-not-applicable-item").val("");}
  });

  $(document).on("change", ".site-rate, .site-gst", function(){
    let siteRateWithGst = $(".site-rate-with-gst")
    let siteGst = $(".site-rate").val();

    if($(".site-gst").prop('checked') == true) {
      siteRateWithGst.val(parseFloat(siteGst).toFixed(2));
    }
    else {
      siteRateWithGst.val((parseFloat(siteGst) + ((siteGst * 10)/100)).toFixed(2));
    }
  });

  $(document).on("change", ".site-rate, .contractor-rate, .rate-type, .contract-rate-type, .site-gst", function(){
    let contractorRate = $(".contractor-rate").val();
    let rateType = $(".rate-type").val();
    let contractRateType = $(".contract-rate-type").val();
    let siteRate = $(".site-rate").val();
    let valWithGst;

    if($(".site-gst").prop('checked') == true) {
      valWithGst = (parseFloat(siteRate).toFixed(2));
    }
    else {
      valWithGst = ((parseFloat(siteRate) + ((siteRate * 10)/100)).toFixed(2));
    }

    let data = {
      contractorRate: contractorRate,
      rateType: rateType,
      contractRateType: contractRateType,
      siteRate: siteRate,
      valWithGst: valWithGst
    }

    $.ajax({
      type: "GET",
      url:  "/rate_details/admin_margin",
      dataType: "script",
      data: data
    })

  });

  $(document).on("change", ".site-cleaner", function() {
    let cleanerId = $(this).val();
    let siteId = $(".current-site-id").val();

    $.ajax({
      type: "GET",
      url:  "/sites/" + siteId + "/cleaners/" + cleanerId + "/site_cleaner_payout",
      dataType: "script",
      data: { id: cleanerId }
    });
  });
});

$.fn.modal.Constructor.prototype._enforceFocus = function() {

  let cleanerId = $(".site-cleaner").val();
  let siteId = $(".current-site-id").val();

  if (cleanerId) {
  $.ajax({
    type: "GET",
    url:  "/sites/" + siteId + "/cleaners/" + cleanerId + "/site_cleaner_payout",
    dataType: "script",
    data: { id: cleanerId }
  });}

  $(".equipment-test-not-applicable-item").attr("disabled", $(".equipment-test-not-applicable").prop("checked"));

  $(".site-keys").select2({closeOnSelect:false});
};

$(document).on("turbolinks:load", function() {
  let contractId = $(".current-client-contract").val();

  if (contractId) {
    $.ajax({
      type: "GET",
      url:  "/sites/" + contractId + "/contract_status",
      dataType: "script",
      data: { contract_id: contractId }
    });
  }
});
