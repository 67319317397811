$(function() {
  $(document).on("change", ".verification-expiry-date, .verification-due-date", function() {
    let expiryDate = new Date($(".verification-expiry-date").val()).toLocaleString('en-AU');
    let dueDate = new Date($(".verification-due-date").val()).toLocaleString('en-AU');

    if ((expiryDate && expiryDate != "Invalid Date") || (dueDate && dueDate != "Invalid Date")) {
      $.ajax({
        type: "GET",
        url:  "/verifications/update_current_status",
        data: { expiryDate: expiryDate, dueDate: dueDate }
      });
    } else {
      $(".verification-due-status").val("");
    }
  });
});
