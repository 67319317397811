var PG = PG || {};

PG.hoursDistribution = function() {
  let totalHoursOfWeek = $(".shift-week-hours").val();
  let checkedFieldsLength = $('input[type="checkbox"]:checked').length;
  let hoursPerDay = parseFloat(totalHoursOfWeek/checkedFieldsLength).toFixed(2);
  let checkedFields = $('input[type="checkbox"]:checked');

  jQuery.each(checkedFields, function() {
    let shiftDaysHours = $(this).parent().next().next().next().next().find(".general-shift-hours");
    shiftDaysHours.val(hoursPerDay);
    shiftDaysHours.attr("readonly", true);
  })
};

$(function() {
  const array = ["1", "2", "3", "4", "5", "6", "7"];

  array.forEach(function (item, index) {
    $(document).on("change", ".shift-day-"+item, function(){
      if ($(".shift-day-"+item).is(":checked")) {
        $(".shift-start-"+item).attr("required", true).removeAttr("disabled");
        $(".shift-end-"+item).attr("required", true).removeAttr("disabled");
        $(".shift-hours-"+item).attr("required", true).removeAttr("disabled");
        if ($(".shift-distribution").val() == "Flat") {
          PG.hoursDistribution();
        }
      }
      else {
        $(".shift-start-"+item).removeAttr("required").attr("disabled", true);
        $(".shift-start-"+item).val("");
        $(".shift-end-"+item).removeAttr("required").attr("disabled", true);
        $(".shift-end-"+item).val("");
        $(".shift-hours-"+item).removeAttr("required").attr("disabled", true);
        $(".shift-hours-"+item).val("");
        if ($(".shift-distribution").val() == "Flat") {
          PG.hoursDistribution();
        }
      }
    })

    $(document).on("change", ".shift-hours-"+item, function(){
      if ($(".shift-distribution").val() == "Flat") {
        PG.hoursDistribution();
      }
    });
  });

  $(document).on("change", ".shift-distribution", function(){
    if ($(".shift-distribution").val() == "Flat") {
      PG.hoursDistribution();
    }
    else {
      let checkedFields = $('input[type="checkbox"]:checked');
      jQuery.each(checkedFields, function() {
        let shiftDaysHours = $(this).parent().next().next().next().next().find(".general-shift-hours");
        shiftDaysHours.val("");
        shiftDaysHours.removeAttr("readonly");
      })
    }
  });

  $(document).on("change", ".shift-week-hours", function(){
    if ($(".shift-distribution").val() == "Flat") {
      PG.hoursDistribution();
    }
  });
});
