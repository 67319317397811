$(function() {
  $(document).on("change", "#all_sites", function() {
    if ($(this).prop("checked")) {
      $("#public_holiday_state_names").prop("disabled", true);
      $("#public_holiday_client_ids").prop("disabled", true);
      $("#public_holiday_site_ids").prop("disabled", true);
    } else {
      $("#public_holiday_state_names").prop("disabled", false);
      $("#public_holiday_client_ids").prop("disabled", false);
      $("#public_holiday_site_ids").prop("disabled", false);
    }
  });

  $(document).on("click", ".view-more", function() {
    if ($(this).html() === "View More..."){
      $(this).html("View Less...")
    }
    else{
      $(this).html("View More...")
    }
  });

  $(".public-holiday-multiselect-dropdown").select2({ closeOnSelect: false });

  $(document).on("turbolinks:load", function() {
    $(".public-holiday-multiselect-dropdown").select2({ closeOnSelect: false });
  });
});
