var PG = PG || {};

$(function() {
  $(document).on("click", "a[data-remote-popup]", function(event) {
    event.preventDefault();
    PG.Modal.showRemoteModal(this.href, $(this).data("title"), $(this).data("modal-class"));
  });
});

PG.Modal = {
  showRemoteModal: function(url, title, modal_class, options = {}) {
    let closeButtonContent = '<button type="button" class="close modal-close-button" data-dismiss="modal" aria-hidden="true">&times;</button>';
    if (options["close_icon"] == false) closeButtonContent = "";

    let header = '<div class="modal-header"><h4 class="modal-title">' + title + '</h4>' + closeButtonContent + '</div>';

    var modal_content = $('<div class="modal fade"><div class="modal-dialog"><div class="modal-content">' +
                          header + '<div class="modal-body clearfix"></div></div></div></div>');

    if(modal_class) modal_content.find(".modal-dialog").addClass(modal_class);

    modal_content.find(".modal-body").load(url, function() {
      $(this).find("form").prepend('<div class="error-container"></div>');
      modal_content.modal({ backdrop: "static" });
    });

    modal_content.on("hidden.bs.modal", function() {
      modal_content.remove();
    });
  }
};
