$(function() {
  $(".payrun-multiselect-dropdown").select2({ closeOnSelect:false });

  $(document).on("turbolinks:load", function() {
    $(".payrun-multiselect-dropdown").select2({ closeOnSelect:false });
  });

  $(document).on("click", ".payrun-amount-table-toggle", function() {
    $(this).toggleClass("fa-caret-down fa-caret-up");
    $(this).closest("tr").next().toggleClass("hide show");
  });
});
