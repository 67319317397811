$(function() {
  $(document).on("change", ".due-by-date", function() {
    let dueByDate = new Date($(".due-by-date").val()).toLocaleString('en-AU');
    let siteId = $(".equipment-site-id").val();

    if (dueByDate && dueByDate != "Invalid Date") {
      $.ajax({
        type: "GET",
        url:  "/equipments/update_test_and_tag_status",
        data: { dueByDate: dueByDate }
      });
    } else {
      $(".equipment-due-status").val("");
    }
  });
});
