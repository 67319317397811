$(function() {
  $(document).on("click", ".login-form .toggle-password-view", function(e) {
    e.preventDefault();
    let eyeIcon = $(this).find(".fa");
    let passwordField = $(this).closest("div").parent("div").find(".password");

    if (passwordField.attr("type") === "password") {
      eyeIcon.removeClass("fa-eye-slash").addClass("fa-eye");
      passwordField.attr("type", "text");
    } else {
      eyeIcon.removeClass("fa-eye").addClass("fa-eye-slash");
      passwordField.attr("type", "password");
    }
  });

  $(document).on("click", ".change-document-button", function(e) {
    e.preventDefault();
    let targetLabel = $("."+$(this).data("target-field"));
    targetLabel.trigger("click");
  });

  $(document).on("change", ".target-document-field", function(){
    let targetText = $(this).val();
    let fileName = targetText.substring(targetText.lastIndexOf("\\") + 1);
    if(fileName) $(".document-name").html(fileName);
  });

  $(document).on("click", ".deleted-record-link", function(e){
    e.preventDefault();
    alert("Record has been deleted!");
  });

  $(document).on("change", ".site-work-report-filter-form select", function () {
    $(this).parents('form').trigger("submit");
  });

  $(document).on("change", ".public-holiday-filter-form select", function () {
    $(this).parents('form').trigger("submit");
  });

  $(document).on("change", ".variable-pagination-dropdown select", function () {
    $(this).parents('form').trigger("submit");
  });

  $.fn.modal.Constructor.prototype._enforceFocus = function() {
    $('select').select2({
      theme: "bootstrap"
    });
    $(".disable-select2-dependency-pagination").select2('destroy');
  };
});

$(document).on("turbolinks:load", function() {
  $('select').select2({
    theme: "bootstrap"
  });

  $(".disable-select2-dependency-pagination").select2('destroy');

  window.jQuery(document).on('select2:open', ".single-select-dropdown", e => {
    document.querySelector(`.select2-search__field`).focus();
  });
});
