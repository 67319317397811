$(function() {
  $(document).on("click", ".add-shift-in-work-report", function() {
    let addShiftIcon = $(this);
    let dateRowCell = $(this).closest("td");
    let newRowSpanValue = parseInt(dateRowCell.attr("rowSpan")) + parseInt(1);
    let className = dateRowCell.parent().attr("class");
    let lastRowInDate = $("."+ className).last();
    let defaultStyle = lastRowInDate.attr("style");
    let countColumn = $("th").length - 3;
    let siteId = $(this).attr("id");
    let dateValue = addShiftIcon.parent().parent().attr("class");

    lastRowInDate.addClass("target-row");

    $.get("/sites/" + siteId + "/work_reports/add_new_shift_row?column_count=" +countColumn+ "&date=" + dateValue, function() {
      dateRowCell.attr("rowspan", newRowSpanValue);
      let newLastRowInDate = lastRowInDate.next();

      newLastRowInDate.addClass(className);
      newLastRowInDate.attr("style", defaultStyle);
      newLastRowInDate.find(".hidden-date-value").each(function() {
        $(this).val(dateValue);
      });

      let allRowsWithinDate = $("."+className);
      if (allRowsWithinDate.length == 1) {
        newLastRowInDate.find(".hidden-cleaner-id").each(function() {
          let headerIndex = $(this).closest("td").index();
          let cleanerValue = $("th:eq("+headerIndex+")").attr("id");
          $(this).val(cleanerValue);
        });
      }
      else if (allRowsWithinDate.length > 1) {
        newLastRowInDate.find(".hidden-cleaner-id").each(function() {
          let headerIndex = $(this).closest("td").index() + 1;
          let cleanerValue = $("th:eq("+headerIndex+")").attr("id");
          $(this).val(cleanerValue);
        });
      }

      newLastRowInDate.children("td").each(function() {
        let newHeadIndex = $(this).index() + 1;
        if ($("th:eq("+newHeadIndex+")").find(".new-cleaner-header-cell").is(":hidden")) {
          $(this).hide();
        }
      });

      newLastRowInDate.find("select").select2({
        theme: "bootstrap"
      });
    });
  })

  $(document).on("click", ".add-empty-staff-cell", function() {
    let siteId = $(this).attr("id");
    let rowCount = $("tr").length;
    $.get("/sites/" + siteId + "/work_reports/add_empty_cleaner_cell?row_count=" +rowCount, function() {
      $(".site-wr-date-container").each(function() {
        let dateValue = $(this).parent().find("div").first().attr("class");
        let className = $(this).parent().attr("class");
        $("."+className).each(function() {
          if ($(this).find(".new-shift-select").length != 0) {
            let shiftValue = $(this).find(".new-shift-select").val();
            $(this).find(".hidden-shift-type-id").each(function() {
              $(this).val(shiftValue);
            });
          }
          else if ($(this).find(".given-shift-type-value").length != 0) {
            let shiftValue = $(this).find(".given-shift-type-value").attr("id");
            $(this).find(".hidden-shift-type-id").each(function() {
              $(this).val(shiftValue);
            });
          }

          $(this).find(".hidden-date-value").each(function() {
            $(this).val(dateValue);
          });
          $(this).find("td:last-child").find("select").select2({theme: "bootstrap"});
          if ($(this).find("td:nth-last-child(2)").is(":hidden")) {
            $(this).find("td:last-child").hide();
          }
        });
      });
    });
  })

  $(document).on("change", ".new-shift-select", function() {
    let shiftValue = $(this).val();
    $(this).closest("td").nextAll().each(function() {
      $(this).find(".hidden-shift-type-id").val(shiftValue);
    });
  });

  $(document).on("change", ".new-cleaner-select", function() {
    let cleanerValue = $(this).val();
    let headIndex = $(this).parent().parent().index();
    $(this).parent().attr("id", cleanerValue);
    $(".site-wr-date-container").each(function() {
      let className = $(this).parent().attr("class");
      let allRowsWithinDate = $("."+className);
      allRowsWithinDate.first().find("td:eq("+headIndex+")").find(".hidden-cleaner-id").val(cleanerValue);
      allRowsWithinDate.slice(1, allRowsWithinDate.length).each(function() {
        let headIndexOtherRows = headIndex - 1;
        $(this).find("td:eq("+headIndexOtherRows+")").find(".hidden-cleaner-id").val(cleanerValue);
      });
    });
  });

  $(document).on("click", ".staff-cell-remove", function() {
    $(this).parent().next().show();
    $(this).parent().hide();
    $(this).parent().find("input, select").attr("disabled", true).removeAttr("required");
  })

  $(document).on("click", ".staff-cell-add", function() {
    $(this).parent().prev().show().css("display","flex");
    $(this).parent().prev().find("input, select").attr("required", true).removeAttr("disabled");
    $(this).parent().hide();
  })

  $(document).on("click", ".shift-add-icon", function() {
    $(this).hide();
    $(this).prev().show();
    $(this).prev().find("input, select").attr("required", true).removeAttr("disabled");
    $(this).parent().nextAll().show();
    $(this).parent().nextAll().each(function() {
      $(this).find("input:visible, select:visible").attr("required", true).removeAttr("disabled");
    });
  })

  $(document).on("click", ".shift-remove-icon", function() {
    $(this).parent().hide();
    $(this).parent().find("input, select").attr("disabled", true).removeAttr("required");
    $(this).parent().next().show();
    $(this).parent().parent().nextAll().hide();
    $(this).parent().parent().nextAll().each(function() {
      $(this).find("input, select").attr("disabled", true).removeAttr("required");
    });
  })

  $(document).on("click", ".remove-cleaner-column", function() {
    $(this).parent().next().show();
    $(this).parent().hide();
    $(this).parent().find("input, select").attr("disabled", true).removeAttr("required");
    let headIndex = $(this).closest("th").index();
    $(".site-wr-date-container").each(function() {
      let className = $(this).parent().attr("class");
      let allRowsWithinDate = $("."+className);
      allRowsWithinDate.first().find("td:eq("+headIndex+")").hide();
      allRowsWithinDate.first().find("td:eq("+headIndex+")").find("input, select").attr("disabled", true).removeAttr("required");
      allRowsWithinDate.slice(1, allRowsWithinDate.length).each(function() {
        let headIndexOtherRows = headIndex - 1;
        $(this).find("td:eq("+headIndexOtherRows+")").hide();
        $(this).find("td:eq("+headIndexOtherRows+")").find("input, select").attr("disabled", true).removeAttr("required");
      });
    });
  })

  $(document).on("click", ".cleaner-column-reload-icon", function() {
    $(this).prev().show();
    $(this).hide();
    $(this).prev().find("input, select").attr("required", true).removeAttr("disabled");
    let headIndex = $(this).closest("th").index();
    $(".site-wr-date-container").each(function() {
      let className = $(this).parent().attr("class");
      let allRowsWithinDate = $("."+className);

      if (allRowsWithinDate.first().find("td:eq("+headIndex+")").prev().is(":visible")) {
        allRowsWithinDate.first().find("td:eq("+headIndex+")").show();
        allRowsWithinDate.first().find("td:eq("+headIndex+")").find("input, select").attr("required", true).removeAttr("disabled");
      }
      allRowsWithinDate.slice(1, allRowsWithinDate.length).each(function() {
        let headIndexOtherRows = headIndex - 1;

        if ($(this).find("td:eq("+headIndexOtherRows+")").prev().is(":visible")) {
          $(this).find("td:eq("+headIndexOtherRows+")").show();
          $(this).find("td:eq("+headIndexOtherRows+")").find("input, select").attr("required", true).removeAttr("disabled");
        }
      });
    });
  })

  $(document).on("click", ".remove-date-row", function() {
    let currentRowSpan = $(this).parent().parent().parent().attr("rowSpan") - 1;
    $(this).parent().parent().hide();
    $(this).parent().parent().parent().nextAll().hide();
    $(this).parent().parent().parent().nextAll().each(function() {
      $(this).find("input, select").attr("disabled", true).removeAttr("required");
    });
    let rowsToBeRemoved = $(this).parent().parent().parent().parent().nextAll().slice(0, currentRowSpan);
    jQuery.each(rowsToBeRemoved, function() {
      $(this).children().hide();
      $(this).children().each(function() {
        $(this).find("input, select").attr("disabled", true).removeAttr("required");
      });
    })
    $(this).parent().parent().next().show();
  })

  $(document).on("click", ".date-row-refresh-icon", function() {
    let currentRowSpan = $(this).parent().attr("rowSpan") - 1;
    let rowsToBeShown = $(this).parent().parent().nextAll().slice(0, currentRowSpan);
    $(this).hide();
    $(this).parent().nextAll().show();
    $(this).parent().nextAll().each(function() {
      $(this).find("input:visible, select:visible").attr("required", true).removeAttr("disabled");
    });
    $(this).prev().show();
    $(this).prev().find("input:visible, select:visible").attr("required", true).removeAttr("disabled");
    jQuery.each(rowsToBeShown, function() {
      $(this).children().show();
      $(this).children().find("input:visible, select:visible").attr("required", true).removeAttr("disabled");
    })
  })

  $(".empty-cleaner-cell-initial").each(function() {
    $(this).find("input, select").attr("disabled", true).removeAttr("required");
  });

  $(".empty-shift-cell-initial").nextAll().hide();

  $(document).on("turbolinks:load", function() {
    $(".empty-cleaner-cell-initial").each(function() {
      $(this).find("input, select").attr("disabled", true).removeAttr("required");
    });
    $(".empty-shift-cell-initial").nextAll().hide();
  });
});
